<template>
  <div class="nav-menu noselect" :style="{ height: `${viewportHeight}px` }">
    <div
      class="nav-menu__menu-container"
      :class="{ 'nav-menu__menu-container--active': active }"
      ref="menu"
    >
      <div
        class="nav-menu__menu-background"
        :class="{ 'nav-menu__menu-background--active': active }"
        @click="() => handleActiveControl(false)"
      ></div>
      <template v-if="GET_IS_ABOVE_BREAKPOINT('lg')">
        <NavigationMenuDesktop
          :active="active"
          @activated="handleActiveControl"
        />
      </template>
      <template v-else>
        <NavigationMenuMobile :active="active" />
      </template>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex"

import NavigationMenuDesktop from "./NavigationMenuDesktop.vue"
import NavigationMenuMobile from "./NavigationMenuMobile.vue"

export default {
  props: {
    active: Boolean
  },

  components: {
    NavigationMenuDesktop,
    NavigationMenuMobile
  },

  methods: {
    ...mapMutations("ui", ["SET_ROUTE_TRANSITION_STATE"]),
    ...mapActions("identity", ["SIGN_OUT"]),

    handleActiveControl(val) {
      this.$emit("activated", val)
    }
  },

  computed: {
    ...mapState("ui", ["routeTransitionState"]),
    ...mapGetters("breakpoints", [
      "GET_IS_BELOW_BREAKPOINT",
      "GET_IS_ABOVE_BREAKPOINT"
    ]),
    ...mapState("scroll", ["viewportHeight"]),
    ...mapState("identity", ["authJWT"])
  },

  watch: {
    routeTransitionState(val) {
      if (val) {
        this.$emit("activated", false)
      }
    }
  }
}
</script>

<style lang="scss">
.nav-menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;

  &__menu-container {
    position: absolute;
    top: $nav-height-default;
    left: 0;
    width: 100%;
    height: calc(100% - #{$nav-height-default});

    @include media-breakpoint-up(md) {
      top: $nav-height-md;
      height: calc(100% - #{$nav-height-md});
    }

    @include media-breakpoint-up(lg) {
      top: $nav-height-lg;
      height: calc(100% - #{$nav-height-lg});
    }

    @include media-breakpoint-up(xl) {
      top: $nav-height-xl;
      height: calc(100% - #{$nav-height-xl});
    }
  }

  &__menu-background {
    background-color: rgba($bleach-black, 0.5);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0;
    transition: opacity 0.2s;

    &--active {
      opacity: 1;
      pointer-events: all;
    }
  }
}
</style>
